@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

.recent-products-table {
    width: 100%;
    white-space: nowrap;
    padding: rem(0px);
    margin-bottom: rem(10px);

    .store-name-cell {
      max-width: rem(300px);
    }
  
  
  th {
    font-weight: bolder !important;
  }
  
  }


