@import 'styles/all.scss';

.OrderInfo {
  .title {
    text-align: center;
  
  }
  
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
    .label, .value {
      flex-shrink: 0;
    }
  }
  
  .UnauthLayout-container__content {
    min-width: rem(423px);
    width: fit-content;
  }
  
  .button-row {
    margin-top: 1.5rem;
    .action-buttons {
      width: 45%;
    }
  }
  
  .OrderRequest_alert {
    margin-bottom: 1rem;
  }
  
  
  .products-table {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: $color-light-grey;
    border-radius: 8px;
    border: 1px solid $color-light-gray-2;
    .product {
      border-bottom: 2px solid $color-light-gray-2;
      background-color: $color-nude;
      &:last-child {
        border-bottom: none;
      }
      &:nth-of-type(odd) {
        background-color: $color-light-grey;
      }
    }
    .product-info-row {
      
      padding-left: .5rem;
      padding-right: .5rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      .note {
        font-weight: bold;
      }
      &.note-row {
        border-top: 1px solid $color-light-gray-2;
        padding: .5rem .5rem .5rem 2rem;
      }
      .product-column,
      .product-extra-info-column {
        margin: .5rem;
        width: 105px;
        text-align: center;
        
        img, &.image-column {
          width: 105px;
          height: auto;
        }
      }
      .product-extra-info-column {
        width: 130px;
        text-align: left;
      }
      .product-note {
        margin-left: 4px;
        max-width: 455px;
      }
    }
    .table-divider {
      margin: 0;
    }
  
  }
  
}
