@import 'styles/all.scss';

.ProductBaseForm {
  display: flex;
  flex-direction: column;
  gap: rem(40px);

  @include media('<md') {
    gap: rem(24px);
  }

  &-main-section {
    display: flex;
    gap: rem(40px);

    @include media('<lg') {
      flex-direction: column;
    }

    @include media('<md') {
      gap: rem(24px);
    }

    &__details {
      flex: 0 1 410px;
      margin-right: auto;
      gap: rem(40px);
      display: flex;
      flex-direction: column;

      @include media('<=md') {
        flex: 1;
        margin: 0px;
      }

      &-price {
        display: flex;
        align-items: flex-start;
        gap: rem(18px);

        &-content {
          flex: 0 1 410px;

          &-value {
            line-height: rem(40px);
            font-weight: $font-weight-bold;
            margin: 0px;

          }
        }
      }
      @include media('<md') {
        flex: 0;
        gap: rem(24px);
      }
    }
    &__uploadImage {
      max-width: rem(262px);
    }
  }
}
