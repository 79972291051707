@use 'sass:map';
@import 'styles/all.scss';

.StoreLayoutContent {
  display: flex;
  flex-direction: row;
  background-color: $color-light-grey;
  justify-content: center;

  .StoreLayoutContent_container {
    width: 100%;
    min-width: rem(300px);
    max-width: map.get($breakpoints, 'xxl');

    @include media('<=xxl') {
      padding: rem(0px 100px);
    }

    @include media('<=xl') {
      padding: rem(0px 40px);
    }

    @include media('<xl') {
      padding: rem(0px 16px);
    }
  }
}
