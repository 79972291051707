.OrderDetailPage_content {
    min-width: 423px;
    width: fit-content;
    margin: auto;
    .ui-kit-card-content {
      padding: 0;
    }
    .OrderDetailPage_content-title {
      text-align: center;
      margin: 1rem 0;
      &-divider {
        margin: .5rem 0;
      }
    }
    .OrderDetails {
      padding: 0 1rem;
      &__resend-email {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem 1rem 2rem 1rem;
        &-input-row {
          margin-top: 1rem;
          display: flex;
          align-items: flex-end;
          gap: 1rem;
          justify-content: center;
          .OrderDetails__resend-email-button {
            width: 166px;
          }
        }
  
        .ui-kit-input-helper-text {
          max-width: 500px;
          text-align: center;
        }
      }
    }
  }