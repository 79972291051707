@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';


.filters-box {
  width: 100%;
  border-radius: rem(4px);
  overflow: hidden;
  $color-secondary-main: map.get($colors, 'secondary', 'main');
  background-color: color.change($color-secondary-main, $alpha: 0.05);

  &-header {
    user-select: none;
    min-height: rem(72px);
    width: 100%;
    padding: rem(12px 20px);

    @include media('<md') {
      padding: rem(6px 12px);
    }
  }

  &-content {
    background-color: white; 
    border-radius: rem(20px);

    margin: rem(0px 24px 12px);
    padding: rem(18px);
  }
}
