@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

.DashboardSalesPurchase {
  border-radius: 10px;
  border: 1px solid $color-gray-2;
  margin-left: rem(30px);
  background-color: $color-white;
  margin-bottom: rem(15px);
}


.DashboardSalesProducts_subtable {
  padding-left: rem(15px);
  padding-right: rem(15px);
  padding-top: 0;
  padding-bottom: 0;
  margin-left: rem(30px);
  
  table {
    border-spacing: 0 rem(10px);
  }
}

.DashboardSalesReceipt_subtable {
  padding-left: rem(15px);
  padding-right: rem(15px);
  padding-top: 0;
  padding-bottom: 0;
  
  table {
    border-spacing: 0
  }
}