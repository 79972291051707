@use 'sass:map';
@import 'styles/all.scss';

.StoreLayoutHeader {
  background-color: map.get($colors, 'white');
  display: flex;
  height: rem(75px);
  width: 100%;
  padding: 0px;

  position: fixed;
  top: -75px;
  z-index: 5;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 4px $color-nude;

  &-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    max-width: map.get($breakpoints, 'xxl');
    @include media('<=xxl') {
      padding: rem(0px 100px);
    }

    @include media('<=xl') {
      padding: rem(0px 40px);
    }

    @include media('<xl') {
      padding: rem(0px 16px);
    }
  }

  &.fixed {
    top: 0px;
  }

  .StoreLayoutHeader_logoBlock {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }


  .StoreLayoutHeader_textLogo {
    margin-left: rem(20px);
    @include media('<=md') {
      display: none;
    }
  }
}
