@use 'sass:map';
@import 'styles/all.scss';

.StoreCheckout_loading {
    min-height: 100vh;
}

.StoreCheckout_layout {
    margin-top: rem(75px);
    min-height: 100vh;
   
  .StoreCheckout_content {
    padding: rem(44px 32px 60px);
    max-width: map.get($breakpoints, 'xxl');
    margin: 0 auto;

    @include media('<md') {
      padding: rem(0 16px);
    }

    &__pageHeader{
      margin-bottom: rem(24px);
    }
    &__retryButton{
      margin-bottom: rem(24px);
      margin-top: rem(24px);
    }
  }
}