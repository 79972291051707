@use 'sass:map';
@import 'styles/all.scss';

.StoreCheckout_layout {
    margin-top: rem(75px);
    min-height: 100vh;
   
  .StoreCheckout_content {
    padding: rem(24px 32px 60px);
    max-width: map.get($breakpoints, 'xxl');
    margin: 0 auto;

    @include media('<md') {
      padding: rem(0 16px);
    }
  }

}
.StoreCheckout-fix-actions{
  position: fixed;
  bottom: 0;
  left: 0;
  padding: rem(16px 32px);
  width: 100%;
  text-align: center;
  box-shadow: 0px -4px 4px $color-nude;
  background-color: $color-white;

  &-content {  
    width: 100%;
    margin: 0 auto;
    max-width: map.get($breakpoints, 'xxl');
  }
}

// Styles for count controls component
.StoreCheckout_orderCellQuantityHeader {
  text-align: center;
}
.StoreCheckout_orderCellQuantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.StoreCheckout_orderCellQuantityButton {
  height: rem(35px);
  width: rem(35px);
  padding: 0;
  border-radius: 50%;
}

.StoreCheckout_orderCellQuantityButtonIcon {
  height: rem(14px);
  width: rem(14px);
}

.StoreCheckout_orderCellQuantityValue {
  margin: rem(0 10px);
}