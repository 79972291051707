@import 'styles/all.scss';

.CheckoutForm_customerFormAgreementWrapper {
  margin-bottom: rem(20px);
  font-size: rem(12px);

  .CheckoutForm_customerFormAgreement {
    display: flex;
    margin-top: rem(16px);
    margin-bottom: rem(8px);
    font-size: rem(12px);

    a {
      padding: 0;
      height: auto;
      line-height: auto;
    }

    .CheckoutForm_checkoutFormCheckboxWrapper {
      padding-right: rem(15px);
    }

    .ant-checkbox, .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-radius: rem(3px);
        width: rem(25px);
        height: rem(25px);
      }
    }
  }
}
