@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

.DashboardSales_table {
  width: 100%;
  white-space: nowrap;
  padding: rem(0px);

  table {
    th {
      font-weight: bolder !important;
    }
  }
  
  .store-name-cell {
    max-width: rem(300px);
  }
}
